import {
    Datagrid,
    DateField, ExportButton,
    FilterButton,
    FunctionField,
    List,
    TextField,
    TopToolbar
} from "react-admin";
import React from "react";
import {TaskRequestMessage} from "../../../data/exploitation/taskrequestmessage";
import ChargeboxesSubMenuBar from "../components/ChargeboxesSubMenuBar";
import SearchHintInput from "../../../components/input/SearchHintInput";

const filters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres (sensible à la casse) parmi : chargebox id."} />,
];

const ListActions = () => {
    return <TopToolbar>
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>;
}

export default function TaskRequestList() {
    return <>
        <ChargeboxesSubMenuBar/>
        <List<TaskRequestMessage> sort={{field: "sentAt", order: "DESC"}} filters={filters} actions={<ListActions/>} title={'resources.taskrequestmessages.listTitle'}>
        <Datagrid rowClick="false" bulkActionButtons={false}>
            <DateField source="sentAt" sortable={true} showTime={true} locales={'fr-FR'}/>
            <FunctionField source="chargeboxes" sortable={true} render={(record) => record.chargeboxes[0]}/>
            <TextField source="taskType" sortable={true}/>
            <TextField source="key" sortable={true}/>
            <TextField source="value" sortable={true}/>
            <TextField source="connectorId" sortable={true}/>
        </Datagrid>
    </List>
    </>
}
