import {
    Button,
    Confirm,
    LinearProgress, SaveButton,
    SimpleForm, Toolbar,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh,
    useTranslate
} from "react-admin";
import {Chargebox} from "../../../../data/exploitation/chargebox";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import SelectTagInput from "./SelectTagInput";
import {E55cApiDataProvider} from "../../../../../providers/dataProvider";

export default function TagsField({source, variant = 'column'}: {source: string, variant?: 'column' | 'row'}) {
    const record = useRecordContext<Chargebox>();

    return  <Box sx={{display: "flex", flexDirection: variant, gap: 1}}>
        {record!.tags?.map((tag, k) => <TagChip key={k} tag={tag}/>)}
        <ButtonAddTag />
    </Box>;
}

function ButtonAddTag() {
    const record = useRecordContext<Chargebox>();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const provider = useDataProvider<E55cApiDataProvider>();
    const translate = useTranslate();

    const handleClose = () => setOpen(false);

    const handleSubmit = ({tag}: any) => {
        setLoading(true);
        provider.chargeboxSetTag(record!.id, tag)
            .then((json: any) => {
                notify('resources.chargeboxes.actions.add_tag.success');
                refresh();
                setOpen(false);
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('Erreur d ajout de tag', {type: "error"});
                }
            })
            .finally(() => setLoading(false));
    }

    return <>
        <Button
            onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
            }}
            label={'resources.chargeboxes.actions.add_tag.label'}
            variant={'contained'} color="info" startIcon={<AddIcon/>}
            data-testid="setTagButton" />
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('resources.chargeboxes.actions.add_tag.label')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate('resources.chargeboxes.actions.add_tag.dialog.text')}
                </DialogContentText>
                <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar loading={loading}/>} data-testid="setTagForm" record={{tag: ""}}>
                    <SelectTagInput source="tag" />
                </SimpleForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} label={translate('cta.cancel')} />
            </DialogActions>
        </Dialog>
    </>
}

const TagChip = ({tag}: {tag: string}) => {
    const record = useRecordContext<Chargebox>();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const provider = useDataProvider<E55cApiDataProvider>();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        setLoading(true);
        provider.chargeboxUnsetTag(record!.id, tag)
            .then((json: any) => {
                notify('resources.chargeboxes.actions.remove_tag.success');
                refresh();
                setOpen(false);
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('Erreur de suppression de tag', {type: "error"});
                }
            })
            .finally(() => {
                setOpen(false);
                setLoading(false);
            });
    };

    return (
        <>
            <Chip label={tag} variant="outlined" onDelete={handleClick}/>
            <Confirm
                isOpen={open}
                loading={loading}
                title={`resources.chargeboxes.actions.remove_tag.confirm.title`}
                content="resources.chargeboxes.actions.remove_tag.confirm.content"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
}

const FormToolbar = ({loading}: {loading: boolean}) => {
    return <Toolbar sx={{justifyContent: "center"}}>
        {loading ? <LinearProgress/> : <SaveButton data-testid="setTagForm"/>}
    </Toolbar>
}
