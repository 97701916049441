import {InputProps, SearchInput, useTranslate} from "react-admin";
import HintIcon from "@mui/icons-material/LightbulbOutlined";
import Tooltip from '@mui/material/Tooltip';
import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";

interface SearchHintInputProps extends InputProps {
    hint: string
}

export default function SearchHintInput({hint, ...rest} : SearchHintInputProps) {
    const translate = useTranslate();
    return (<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <SearchInput {...rest} />
        <Tooltip title={<Typography variant={'body2'}>{translate(hint)}</Typography>} sx={{marginTop: "8px", color: 'text.disabled'}}><HintIcon/></Tooltip>
    </Box>);
}
