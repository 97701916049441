import {
    Datagrid,
    DateField, ExportButton,
    FilterButton,
    List,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useRecordContext
} from "react-admin";
import {
    EvseMessage,
    EvseMessageBootNotification,
    EvseMessageStatusNotification
} from "../../../data/exploitation/evsemessage";
import ChargeboxesSubMenuBar from "../components/ChargeboxesSubMenuBar";
import SearchHintInput from "../../../components/input/SearchHintInput";
import React from "react";

export default function EvseMessageList() {
    return <>
        <ChargeboxesSubMenuBar/>
        <List<EvseMessage> sort={{field: "receivedAt", order: "DESC"}} filters={filters} actions={<ListActions/>} title={'resources.evsemessages.listTitle'}>
        <Datagrid rowClick="false" bulkActionButtons={false} expand={<ExpandReceivedMessage/>}>
            <DateField source="receivedAt" sortable={true} showTime={true} locales={'fr-FR'}/>
            <TextField source="chargeboxId" sortable={true} />
            <TextField source="type" sortable={true}/>
        </Datagrid>
    </List>
    </>
}

const filters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres (sensible à la casse) parmi : chargebox id."} />,
];

const ListActions = () => {
    return <TopToolbar>
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>;
}

function ExpandReceivedMessage() {
    const record = useRecordContext<EvseMessage>();

    if (!record) {
        return null;
    }

    switch (record.type) {
        case 'BootNotification':
            return <ExpandBootNotification record={record as EvseMessageBootNotification}/>;
        case 'StatusNotification':
            return <ExpandStatusNotification record={record as EvseMessageStatusNotification}/>;
    }

    return null;
}

function ExpandBootNotification({ record }: { record: any }) {
    if (!record) {
        return null;
    }

    return <SimpleShowLayout>
        {record.ocppBootNotification && Object.keys(record.ocppBootNotification).map((key) => {
            return <TextField key={key} source={`ocppBootNotification.${key}`}/>
        })}
    </SimpleShowLayout>;
}

function ExpandStatusNotification({ record }: { record: EvseMessageStatusNotification }) {
    if (!record) {
        return null;
    }

    return <SimpleShowLayout>
        <TextField source="evse.status"/>
        <TextField source="uid"/>
        {record.ocppStatusNotification && Object.keys(record.ocppStatusNotification).map((key) => {
            return <TextField key={key} source={`ocppStatusNotification.${key}`}/>
        })}
    </SimpleShowLayout>;
}
