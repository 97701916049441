import {
    ArrayField, Datagrid, DateField,
    RecordContextProvider,
    SimpleShowLayout,
    TextField,
    useRecordContext
} from "react-admin";
import {Location} from "../../../../data/exploitation/location";
import Box from "@mui/material/Box";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import React from "react";

export default function EvsesField ({ source } : { source: string }) {
    const record = useRecordContext<Location>();

    if (!record || !record.evses) {
        return null;
    }

    return (<Box>
        {record.evses.map((evse) => (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>{evse.uid}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RecordContextProvider value={evse}>
                        <SimpleShowLayout>
                            <TextField source="status"/>
                            <TextField source="status_ocpp"/>
                            <TextField source="status_mode"/>
                            <TextField source="physical_reference"/>
                            <TextField source="last_updated"/>
                            <TextField source="last_updated_ocpp"/>
                            <ArrayField source="connectors" label={'Connectors'}>
                                <Datagrid bulkActionButtons={false} rowClick={false}>
                                    <TextField source="id"/>
                                    <TextField source="amperage"/>
                                    <TextField source="format"/>
                                    <DateField source="last_updated" showTime={true}/>
                                    <TextField source="power_type"/>
                                    <TextField source="standard"/>
                                    <TextField source="voltage"/>
                                </Datagrid>
                            </ArrayField>
                        </SimpleShowLayout>
                    </RecordContextProvider>
                </AccordionDetails>
            </Accordion>
        ))}
    </Box>);
}
